export const affiliateLinks = [
  {
    href: "https://shareasale.com/r.cfm?b=1734640&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/2005561_300x250.jpg",
    alt: "Spider-Man and Mary Jane Maquette by Sideshow Collectibles",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1653392&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/300662_300x250.jpg",
    alt: "Doctor Strange Maquette by Sideshow Collectibles",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1715429&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/700166_300x250.jpg",
    alt: "Sleepover Sirens Designer Collectible Toy by Unruly Industries™",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1705885&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/700166_300x250.jpg",
    alt: "The Mandalorian™ Premium Format™ Figure by Sideshow Collectibles",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1600013&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/700069_300x250.jpg",
    alt: "Smelter Skelter Designer Collectible Toy by Unruly Industries™",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1082007&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/general1.jpg",
    alt: "SideShow General Banners",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1678193&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/73004/300235_300x250.jpg",
    alt: "Asajj Ventress™ Mythos Statue by Sideshow Collectibles",
  },
  {
    href: "https://shareasale.com/r.cfm?b=685523&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/26748/03-01-dont-get-caught-list-300x250.png",
    alt: "Don't get caught plagiarizing",
  },
  {
    href: "https://shareasale.com/r.cfm?b=822421&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/26748/01-cure-300x250_00.png",
    alt: "Grammarly Writing Support",
  },
  {
    href: "https://shareasale.com/r.cfm?b=898515&u=3246264&m=73004&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/26748/491-search-banner-ads-5_300x250.png",
    alt: "",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1788633&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/56835/YodaShoes-300x250.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1785720&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/56835/WampaRug-300x250.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1785717&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/StrangerThings-300x250.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1785714&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/56835/HPShoes-300x250.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1785706&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/Ghostbusters-300x250.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1725099&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/Care-Bears-BL-300x250.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1286842&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/AffiliateBanner11.20_FUN_300x250.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1156132&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/CareBears_AffiliateBanner_300x250-02.jpg",
    alt: "Shop Now",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1001974&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/56835/DC-1-336x280.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=683340&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/336px-x-280px-large-rectangle_00.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=683341&u=3246264&m=56835&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/56835/FUN-300x250-black-series.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1147834&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc:
      "https://static.shareasale.com/image/8908/18_03-Witcher_300x250.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1144597&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/18_02-TFAW_300x250-2.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=1144592&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/18_02_Games_300x250.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=834642&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/HarleyQuinn300x250.png",
    alt: "Get ready for Doctor Strange with TFAW.com!",
  },
  {
    href: "https://shareasale.com/r.cfm?b=835069&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/HarrowCounty300x250.png",
    alt: "Up To 40% Off Harrow County Comics &amp; Graphic Novels",
  },
  {
    href: "https://shareasale.com/r.cfm?b=536615&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/300_Inhuman.jpg",
    alt: "Get Guardians of the Galaxy Comics and Graphic Novels at TFAW",
  },
  {
    href: "https://shareasale.com/r.cfm?b=464285&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/300_Toys.jpg",
    alt: "Save 10-50% on in-stock toys at TFAW.com.",
  },
  {
    href: "https://shareasale.com/r.cfm?b=464277&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/300_Statues.jpg",
    alt: "Save 20 on all pre-order statues at TFAW.com!",
  },
  {
    href: "https://shareasale.com/r.cfm?b=387363&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/300_StarTrek.jpg",
    alt: "Find Star Trek comics, toys, statues, and collectibles at TFAW.com!",
  },
  {
    href: "https://shareasale.com/r.cfm?b=126652&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/300_statues1.jpg",
  },
  {
    href: "https://shareasale.com/r.cfm?b=188074&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/300_aliens3.gif",
    alt: "Get the new Aliens comic book series!",
  },
  {
    href: "https://shareasale.com/r.cfm?b=214400&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/300_SWGeneral.jpg",
    alt: "Save 10-50% on Star Wars Comics, Graphic Novels, Toys &amp; Statues",
  },
  {
    href: "https://shareasale.com/r.cfm?b=231364&u=3246264&m=8908&urllink=&afftrack=",
    imgSrc: "https://static.shareasale.com/image/8908/300_Avengers.jpg",
    alt: "Get Avengers Comics, Graphic Novels & More at TFAW.com.",
  },
];

export const AmazonAffiliateAds = [
  {
    bannerId: "banner_godfather_harlem",
    imageId: "image_godfather_harlem",
    bannerScript: `
      <script type="text/javascript">
          amzn_assoc_ad_type = "banner";
          amzn_assoc_marketplace = "amazon";
          amzn_assoc_region = "US";
          amzn_assoc_placement = "assoc_banner_placement_default";
          amzn_assoc_campaigns = "primevideo";
          amzn_assoc_banner_type = "category";
          amzn_assoc_p = "49";
          amzn_assoc_isresponsive = "false";
          amzn_assoc_banner_id = "0WH2N3FD6WBZFPDN9B82";
          amzn_assoc_width = "300";
          amzn_assoc_height = "600";
          amzn_assoc_tracking_id = "popmysticid08-20";
          amzn_assoc_linkid = "42d8c5226dfdb6a2e4f6be3719f720e8";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_govt_assistance",
    imageId: "image_govt_assistance",
    bannerScript: `
      <script type="text/javascript">
        amzn_assoc_ad_type = "banner";
        amzn_assoc_marketplace = "amazon";
        amzn_assoc_region = "US";
        amzn_assoc_placement = "assoc_banner_placement_default";
        amzn_assoc_campaigns = "primediscounted";
        amzn_assoc_banner_type = "category";
        amzn_assoc_p = "49";
        amzn_assoc_isresponsive = "false";
        amzn_assoc_banner_id = "05A95CG2YZK0W23JZA82";
        amzn_assoc_width = "300";
        amzn_assoc_height = "600";
        amzn_assoc_tracking_id = "popmysticid08-20";
        amzn_assoc_linkid = "604eb2ad5c9598c0666a1f1b3cf8f477";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_ebt_discount",
    imageId: "image_ebt_discount",
    bannerScript: `
      <script type="text/javascript">
        amzn_assoc_ad_type = "banner";
        amzn_assoc_marketplace = "amazon";
        amzn_assoc_region = "US";
        amzn_assoc_placement = "assoc_banner_placement_default";
        amzn_assoc_campaigns = "primediscounted";
        amzn_assoc_banner_type = "category";
        amzn_assoc_p = "49";
        amzn_assoc_isresponsive = "false";
        amzn_assoc_banner_id = "19ZT7SD3PJ9F8WM6VD02";
        amzn_assoc_width = "300";
        amzn_assoc_height = "600";
        amzn_assoc_tracking_id = "popmysticid08-20";
        amzn_assoc_linkid = "d0f2c1bdfa56290babffa432c32de3a2";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_hotel_trans",
    imageId: "image_hotel_trans",
    bannerScript: `
      <script type="text/javascript">
        amzn_assoc_ad_type = "banner";
        amzn_assoc_marketplace = "amazon";
        amzn_assoc_region = "US";
        amzn_assoc_placement = "assoc_banner_placement_default";
        amzn_assoc_campaigns = "primevideo";
        amzn_assoc_banner_type = "category";
        amzn_assoc_p = "49";
        amzn_assoc_isresponsive = "false";
        amzn_assoc_banner_id = "1FMKX9VZV155ZGW1APG2";
        amzn_assoc_width = "300";
        amzn_assoc_height = "600";
        amzn_assoc_tracking_id = "popmysticid08-20";
        amzn_assoc_linkid = "6bc382c98efe5901ebb92dc137353796";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_99_cents",
    imageId: "image_99_cents",
    bannerScript: `
      <script type="text/javascript">
        amzn_assoc_ad_type = "banner";
        amzn_assoc_marketplace = "amazon";
        amzn_assoc_region = "US";
        amzn_assoc_placement = "assoc_banner_placement_default";
        amzn_assoc_campaigns = "primevideo";
        amzn_assoc_banner_type = "category";
        amzn_assoc_p = "49";
        amzn_assoc_isresponsive = "false";
        amzn_assoc_banner_id = "1GRTMQG5A8QP6ZZB4KG2";
        amzn_assoc_width = "300";
        amzn_assoc_height = "600";
        amzn_assoc_tracking_id = "popmysticid08-20";
        amzn_assoc_linkid = "53d79db683692fa31a2fa05dc1af577c";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_save_on_movies",
    imageId: "image_save_on_movies",
    bannerScript: `
      <script type="text/javascript">
        amzn_assoc_ad_type = "banner";
        amzn_assoc_marketplace = "amazon";
        amzn_assoc_region = "US";
        amzn_assoc_placement = "assoc_banner_placement_default";
        amzn_assoc_campaigns = "primevideo";
        amzn_assoc_banner_type = "category";
        amzn_assoc_p = "49";
        amzn_assoc_isresponsive = "false";
        amzn_assoc_banner_id = "0JQ3AMJ1Z8WKNSJPKT02";
        amzn_assoc_width = "300";
        amzn_assoc_height = "600";
        amzn_assoc_tracking_id = "popmysticid08-20";
        amzn_assoc_linkid = "f465901b215d88f71ff72af1317ff160";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_bond_free_trial",
    imageId: "image_bond_free_trial",
    bannerScript: `
      <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "02ECMZF5QDSW8W1VQJR2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
	amzn_assoc_linkid = "0dcac407a70c87f4569e0d4f13b789e3";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_ri_ri_free_trial",
    imageId: "image_ri_ri_free_trial",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "1ZPRMJKMB2SAD3Q097G2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "aceb14a63865e32a4f91129fb3041178";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_cinderella",
    imageId: "image_cinderella",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "1TZ1MA5E26TSGSGPHKG2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "403043d6e5c5fb76becdbb281de396c7";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_prime_day_show",
    imageId: "image_prime_day_show",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "08W1GCT6FNJ3RPY93C02";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "4592884f6e0c9ea11bd4fb3b81d44d89";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_without_remorse",
    imageId: "image_without_remorse",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "017GYK1451MWENYTN682";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "9ed9af854c2b55b8ffc7727f46167c5a";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_comine_2_america",
    imageId: "image_comine_2_america",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0KX1YD6BTFEQ56J0N7G2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "6a49bf78d475e162311e91a60083df67";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },

  {
    bannerId: "banner_paramount_plus",
    imageId: "image_paramount_plus",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "18EJ946DE02M5PHVGY02";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "7e3e9ff95b6368c7571a1c472cceafdc";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_map_of_tiny",
    imageId: "image_map_of_tiny",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "primevideo";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0VRV92B33HJ97RGM4Y82";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "932038d4f7161b04a4c6a61953130d85";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_kym",
    imageId: "image_kym",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0JHC37XEFXP3XGF0ENG2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "c349fc320ba69ca084f4f8cda0ee9672";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_koz",
    imageId: "image_koz",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "1GQJHTZEKSR0443GV7G2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "89857f6b04397e22ddb9133de22d087a";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_she_memes",
    imageId: "image_she_memes",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0SV26ZGXMQZWDCHNMX82";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "52b2769cf4a6a459f80c8055c66efc2f";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_direct_deposit",
    imageId: "image_direct_deposit",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0KSFP7CDDJBJ31ZSCXR2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "1b4af4decbb27dc1918f20e83d72200c";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_champion",
    imageId: "image_champion",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0CS0PY7B0Y10J21DDN02";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "22f57575ca708d90835f4762a25fc901";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_andy_weir",
    imageId: "image_andy_weir",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "1SQ2VEYQWEGSP8DNK0R2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "6be5d08a46613c65efd3d05a857a4d4c";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_3_months_free",
    imageId: "image_3_months_free",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "audible";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0HJMKRY657WDVEQ1DTR2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "3c2b4db40a11aa4032f59d02f526d84c";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_ankle_boots",
    imageId: "image_ankle_boots",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "clothingandshoes";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "0KGG5528RQ692034NDG2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "6e206c7b9086bf9cf104d527fc8e0adc";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_dog_macarons",
    imageId: "image_dog_macarons",
    bannerScript: `
      <script type="text/javascript">
        amzn_assoc_ad_type = "banner";
        amzn_assoc_marketplace = "amazon";
        amzn_assoc_region = "US";
        amzn_assoc_placement = "assoc_banner_placement_default";
        amzn_assoc_campaigns = "pets";
        amzn_assoc_banner_type = "category";
        amzn_assoc_p = "49";
        amzn_assoc_isresponsive = "false";
        amzn_assoc_banner_id = "0N0RYTS6DXQJ9HYS8082";
        amzn_assoc_width = "300";
        amzn_assoc_height = "600";
        amzn_assoc_tracking_id = "popmysticid08-20";
        amzn_assoc_linkid = "f4df002f9d17ead20cd330ddb540535d";
      </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
  {
    bannerId: "banner_adobe_cloud",
    imageId: "image_adobe_cloud",
    bannerScript: `
     <script type="text/javascript">
      amzn_assoc_ad_type = "banner";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_placement = "assoc_banner_placement_default";
      amzn_assoc_campaigns = "software";
      amzn_assoc_banner_type = "category";
      amzn_assoc_p = "49";
      amzn_assoc_isresponsive = "false";
      amzn_assoc_banner_id = "10QETYZXHNAHXX79Y6R2";
      amzn_assoc_width = "300";
      amzn_assoc_height = "600";
      amzn_assoc_tracking_id = "popmysticid08-20";
      amzn_assoc_linkid = "6f18ab2bef8946f43b7ecd01adeb6bef";
     </script>
    `,
    imageScript: `<script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>`,
  },
];
